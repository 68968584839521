<template>
  <div class="line" :class="bigLine ? 'bigLine' : ''">
    <div class="line-title">{{ titleArr[0] }}</div>
    <div class="line-content" :style="type == 'one' ? 'flex:3' : ''">
      <Tooltip
        :max-width="type == 'one' ? 900 : 300"
        style="width: 100%"
        theme="light"
        :transfer="true"
        :content="leftData"
        word-wrap
        placement="top"
      >
        <span
          :class="type == 'one' ? 'oneClass' : ''"
          :style="itemHref[0] ? 'color:blue;cursor:pointer' : ''"
          @click="openUrl(contentArr[0], itemHref[0])"
        >
          {{ leftData }}
        </span>
      </Tooltip>
    </div>
    <div class="line-title" v-if="type == 'dob'">{{ titleArr[1] }}</div>
    <div class="line-content" v-if="type == 'dob'">
      <Tooltip
        :content="rightData"
        max-width="300"
        theme="light"
        :transfer="true"
        style="width: 100%"
        word-wrap
        placement="top"
      >
        <span
          :style="itemHref[1] ? 'color:blue;cursor:pointer' : ''"
          @click="openUrl(contentArr[1], itemHref[1])"
        >
          {{ rightData }}
        </span>
      </Tooltip>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "c-TableItem",
  components: {},
  props: {
    titleArr: {
      type: Array,
      required: true,
    },
    contentArr: {
      type: Array,
    },
    type: {
      type: String,
      default: "dob",
    },
    bigLine: {
      type: Boolean,
      default: false,
    },
    itemHref: {
      type: Array,
      default: function () {
        return [false, false];
      },
    },
  },
  methods: {
    openUrl(url, isHref) {
      if (isHref && url) {
        window.open("http://" + url, "_target");
      }
    },
  },
  computed: {
    leftData() {
      let data = this.contentArr[0] ? this.contentArr[0] : "--";
      if (typeof data == "number") {
        return data.toFixed(2);
      }
      return data;
    },
    rightData() {
      let data = this.contentArr[1] ? this.contentArr[1] : "--";
      if (typeof data == "number") {
        return data.toFixed(2);
      }
      return data;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/common.scss";
.line {
  width: 100%;
  @include flex;
  height: 40px;
  text-align: center;
  line-height: 40px;
  border: 1px solid rgba(214, 215, 217, 1);
  margin-bottom: -1px;
  .line-title:nth-of-type(1) {
    border-left: 1px solid rgba(214, 215, 217, 1);
  }
  .line-title:last-of-type {
    border: 1px solid rgba(214, 215, 217, 1);
  }
  .line-title {
    background: rgba(250, 250, 253, 1);
    flex: 1;
    height: 100%;
    // border-top: 1px solid rgba(214, 215, 217, 1);
    border-right: 1px solid rgba(214, 215, 217, 1);
    // border-bottom: 1px solid rgba(214, 215, 217, 1);
  }
  .line-content {
    background: #fff;
    flex: 1;
    height: 100%;
    // border-top: 1px solid rgba(214, 215, 217, 1);
    border-right: 1px solid rgba(214, 215, 217, 1);
    // border-bottom: 1px solid rgba(214, 215, 217, 1);
    overflow: hidden;
    .ivu-tooltip {
      /deep/.ivu-tooltip-rel {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}
.bigLine {
  height: 120px !important;
  line-height: 30px;
  .line-title {
    line-height: 120px;
  }

  .line-content {
    .ivu-tooltip {
      /deep/.ivu-tooltip-rel {
        overflow: initial;
        white-space: initial;
        .oneClass {
          display: inline-block;
          width: 100%;
          height: 100%;
          text-align: left;
          text-indent: 2em;
        }
      }
    }
  }
}
</style>
