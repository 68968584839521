<template>
<div class="convertibleDetail">
    <div>
        <div class="table-title">
            <div style="float: left" class="title">
                <Tooltip max-width="900" style="width: 100%" :content="formData.btmc" word-wrap theme="light" placement="bottom">{{ formData.btmc }}</Tooltip>
            </div>

            <span style="
            font-size: 14px;
    white-space: nowrap;
    position: absolute;
    right: 100px;
          ">币种：{{ formData.bz }}</span>
            <Icon type="ios-undo" class="return_ico" @click="handleReturn" />
        </div>
        <cTableItem :titleArr="['公告日期', '签订协议日期']" :contentArr="[formData.ggrq, formData.qdxyrq]"></cTableItem>
        <cTableItem :titleArr="['完成收购时间', '并购方式']" :contentArr="[formData.wcsgsj, formData.bgfs]"></cTableItem>
        <cTableItem :titleArr="['收购方/受让方', '收购方/受让方股权比例']" :contentArr="[formData.sgfSrf, formData.sgfSrfgqbl]"></cTableItem>
        <cTableItem :titleArr="['卖方/转让方', '目标公司']" :contentArr="[formData.mfZrf, formData.mbgs]"></cTableItem>
        <cTableItem :titleArr="['目标集团', '交易标的']" :contentArr="[formData.mbjt, formData.jybd]"></cTableItem>
        <cTableItem :titleArr="['标的股权比例', '交易金额(亿元)']" :contentArr="[formData.bdgqbl, formData.jyje]"></cTableItem>
        <cTableItem :titleArr="['收购前股权比例', '收购后股权比例']" :contentArr="[formData.sgqgqbl, formData.sghgqbl]"></cTableItem>
        <!-- <cTableItem :titleArr="['发行地','项目所在地']"
                  :contentArr="['','']"></cTableItem> -->
        <cTableItem :titleArr="['资金来源']" type="one" :contentArr="[formData.zjly]"></cTableItem>
        <cTableItem :titleArr="['收购目的']" type="one" :contentArr="[formData.sgmd]"></cTableItem>
        <cTableItem :titleArr="['原股权结构']" type="one" :contentArr="[formData.ygqjg]"></cTableItem>
        <cTableItem :titleArr="['现股权结构']" type="one" :contentArr="[formData.xgqjg]"></cTableItem>
        <cTableItem :titleArr="['经营范围']" type="one" :contentArr="[formData.jyfw]"></cTableItem>
        <cTableItem :titleArr="['主营业务']" type="one" :contentArr="[formData.zyyw]"></cTableItem>
        <cTableItem :titleArr="['主要资产']" type="one" :contentArr="[formData.zyzc]"></cTableItem>
        <cTableItem :titleArr="['内容摘要']" type="one" :contentArr="[formData.nrzy]"></cTableItem>
        <div class="line">
            <div class="line-title" style="flex: 1">相关公告</div>
            <div class="line-content" style="flex: 3 1 0%">
                <Tooltip max-width="900" style="width: 100%" :content="formData.btmc" word-wrap theme="light" placement="top">
                    <a :href="formData.xgurl" target="_bank">
                        {{ formData.btmc }}
                    </a>
                </Tooltip>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import {
    get_enterprise_jbsg
} from "@/api/houseEnterprise";
import cTableItem from "@/components/cTableItem";
import {
    returnFloat
} from "@/utils";

export default {
    name: "mergerAcquisitionDetails",
    components: {
        cTableItem,
    },
    data() {
        return {
            formData: {},
        };
    },
    mounted() {
        this.getData();
    },
    methods: {
        getData() {
            get_enterprise_jbsg({
                    id: this.$route.query.id
                })
                .then((data) => {
                    this.formData = data || [];
                    let arrTo100 = ["ygdpsrgjc", "bdgqbl", "sgqgqbl", "sghgqbl",'sgfSrfgqbl'];
                    let arrTo2 = ["jyje"];
                    arrTo100.forEach((data) => {
                        if (!isNaN(this.formData[data]) && this.formData[data]) {
                            this.formData[data] =
                                Number(this.formData[data] * 100).toFixed(2) + "%";
                        }
                    });
                    arrTo2.forEach((data) => {
                        if (!isNaN(this.formData[data]) && this.formData[data]) {
                            this.formData[data] = returnFloat(this.formData[data], 2);
                        }
                    });
                })
                .catch((e) => {

                });
        },
        handleReturn() {
            window.history.go(-1);
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/style/common.scss";

.convertibleDetail {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    background: #fff;
    padding: 20px;

    .title {
        font-size: 20px;
        font-weight: 600;
        margin-right: 20px;
        white-space: nowrap;
    }

    h1 {
        font-size: 20px;
        font-weight: bold;
        color: rgba(51, 51, 51, 1);
        line-height: 26px;
        margin-bottom: 20px;
    }

    div.more {
        height: 40px;
        background: rgba(250, 250, 253, 1);
        color: #00b6ff;
        line-height: 40px;
        font-size: 14px;
        text-align: center;
        margin-top: 10px;
        cursor: pointer;
    }

    div {
        font-size: 14px;
        color: rgba(51, 51, 51, 1);

        div.table-title {
            display: flex;
            justify-content: space-between;
            font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
            font-weight: bold;
            padding-bottom: 20px;
            font-size: 20px;
            border-bottom: 1px solid rgba(214, 215, 217, 1);

            .return_ico {
                color: #02b6ff;
                font-size: 26px;
            }
        }

        div {
            @include flex;
            height: 40px;
            line-height: 40px;

            .line-title:nth-of-type(1) {
                border-left: 1px solid rgba(214, 215, 217, 1);
            }

            .line-title {
                background: rgba(250, 250, 253, 1);
                width: 414px;
                border-right: 1px solid rgba(214, 215, 217, 1);
                border-bottom: 1px solid rgba(214, 215, 217, 1);
            }

            .line-content {
                background: #fff;
                width: 414px;
                border-right: 1px solid rgba(214, 215, 217, 1);
                border-bottom: 1px solid rgba(214, 215, 217, 1);
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                text-align: center;
            }
        }
    }
}
</style>
